<template>
  <div>
    <table-component
      ref="tableComponent"
      :model="model"
      :show-form="false"
      url-create-form="/loan-request"
      url-edit-form="/accounts/edit"
      force-paginate
      :permissions="permissions"
    >
      <template v-slot:actions>
        <router-link
          :to="`/loan-requests`"
          class="btn btn-outline-primary"
        >
          Continuar solicitud de crédito
        </router-link>
      </template>
    </table-component>
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Account from '@/dc-it/models/Account'

export default {
  name: 'AccountsView',
  components: { TableComponent },
  data() {
    return {
      model: Account,
      // accountType: null,
      permissions: {
        create: true,
        edit: false,
        delete: false,
        filter: true,
      },
      accountType: 2,
    }
  },
  mounted() {
    this.applyFilter()
  },
  methods: {
    applyFilter() {
      this.$refs.tableComponent.filterFields = [
        {
          field: 'account_type.type',
          value: this.accountType,
        },
      ]
      this.$refs.tableComponent.applyFilter()
    },
  },
}
</script>

<style scoped>

</style>
